import Constants from '../../config.local.js'
import Common from '@/assets/js/common.js'
import CsvMixin from '@/mixins/Csv.js'
import FileMixin from "@/mixins/File.js"
import Countries from '../assets/lang/countries/countries.js'
import HorseMixin from "@/mixins/Horse.js"
import TiersContactTransformer from '@/assets/js/dexie/transformers/TiersContactTransformer.js'
import AccountingPlanTransformer from '@/assets/js/dexie/transformers/AccountingPlanTransformer.js'

import TierTransformer from '@/assets/js/dexie/transformers/TierTransformer'
import TiersHorseTransformer from '@/assets/js/dexie/transformers/TiersHorseTransformer'
import TiersClientAccountTransformer from '../assets/js/dexie/transformers/TiersClientAccountTransformer.js'
import TiersRibTransformer from '../assets/js/dexie/transformers/TiersRibTransformer.js'
import CurrentAccountTransformer from '../assets/js/dexie/transformers/CurrentAccountTransformer.js'
import InvoiceTransformer from '../assets/js/dexie/transformers/InvoiceTransformer.js'
import TiersRelationsTransformer from '@/assets/js/dexie/transformers/TiersRelationsTransformer.js'
import { parsePhoneNumberFromString as parseMax } from 'libphonenumber-js/max'
import parsePhoneNumber from 'libphonenumber-js'
import _cloneDeep from 'lodash/cloneDeep'
import TierCleaner from '@/assets/js/cache/cleaners/TierCleaner'

/* eslint-disable no-useless-escape */

var TiersMixin = {
	mixins: [CsvMixin, HorseMixin],
	data() {
		return {

		}
	},
	methods: {
		loadTier: async function(tiers_id) {
			const table = await this.$storage.db.t('tiers')
			tiers_id = parseInt(tiers_id)

			const tier = await table.get(tiers_id)
			return TierTransformer.process(tier, 'withPhone')
		},

		loadTiers: async function() {
			return this.$storage.db.t('tiers')
			.then(table => {
				return table.where({
					tiers_archive: 0
				})
			})
			.then(col => {
				return col.transform(new TierTransformer('light'))
			})
		},

		loadTiersOnline: async function() {
			const url = this.constructRoute(Constants.TIERS_LISTE_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("TiersMixin::loadTiers", url)
			.catch(error => {
				console.error("TiersMixin::loadTiersOnline => ERROR", error)
				return null
			})
			if(result.retour) return result.retour
			return null
		},

		loadTiersEntity: async function() {
			return this.$storage.db.t('tiers')
			.then(table => {
				return table.where({
					tiers_entity: 1,
					tiers_archive: 0
				})
			})
			.then(col => {
				return col.transform(new TierTransformer('light'))
			})
		},

		loadContactFavoris: async function(tiers_id) {
			return this.$storage.db.t('tiers_contact')
			.then(table => {
				return table.where({
					tierscontact_tiers: parseInt(tiers_id),
					tierscontact_favoris: 1
				})
			})
			.then(col => {
				return col.transform(new TiersContactTransformer('withContactAndFonction'))
			})
		},

		loadTiersContacts: async function(tiers_id) {
			return this.$storage.db.t('tiers_contact')
			.then(table => {
				return table.where({
					tierscontact_tiers: parseInt(tiers_id)
				})
			})
			.then(col => {
				return col.transform(new TiersContactTransformer('withContactAndFonction'))
			})
		},

		loadTiersContact: async function(tiers_id, contact_id) {
			return this.$storage.db.t('tiers_contact')
			.then(table => {
				return table.where({
					tierscontact_tiers: parseInt(tiers_id), 
					tierscontact_contact: parseInt(contact_id)
				})
			})
			.then(col => {
				return col.transform(new TiersContactTransformer())
			})
		},

		loadTiersModels: async function(tiers_id) {
			const url = this.constructRoute(Constants.TIERS_MODELS_URL, { tiers_id: tiers_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
			return this.$request.request_get_api("TiersMixin::loadTiersModels", url)
				.then(res => {
					return res.retour
				})
				.catch(error => {
					console.error("TiersMixin::loadTiersModels => ERROR", error)
					return null
				})
		},

		loadHorse: async function(tiers_id) {
			return this.$storage.db.t('tiers_horse')
			.then(table => {
				return table.where({
					tiershorse_tiers: parseInt(tiers_id),
					tiershorse_valide: 1
				})
				.and(tiershorse => tiershorse.tiershorse_fonction == 'PROPRIO')
			})
			.then(col => {
				return col.transform(new TiersHorseTransformer())
			})
		},

		loadTiersHorse: async function(horse_id) {
			return this.$storage.db.t('tiers_horse')
			.then(table => {
				return table.where({
					tiershorse_horse: parseInt(horse_id),
					tiershorse_valide: 1
				})
				.and(tiershorse => tiershorse.tiershorse_fonction == 'PROPRIO')
			})
			.then(col => {
				return col.transform(new TiersHorseTransformer())
			})
		},

		loadTiersHorseSecondaires: async function(horse_id) {
			return this.$storage.db.t('tiers_horse')
			.then(table => {
				return table.where({
					tiershorse_horse: parseInt(horse_id),
					tiershorse_valide: 1
				})
				.and(tiershorse => tiershorse.tiershorse_fonction !== 'PROPRIO')
			})
			.then(col => {
				return col.transform(new TiersHorseTransformer('withFonctionAndTiersContact'))
			})
		},

		loadHorseCurrentPart: async function(tiers_id) {
			let tiers = await this.$storage.db.t('tiers')
			.then(table => {
				return table.get(parseInt(tiers_id))
			}).then(tier => {
				return TierTransformer.process(tier, 'withTiersHorseCurrentPart')
			})

			let tiers_horse = []
			tiers.tiers_horse.forEach(th => {
				let thp = th.tiers_horse_part
                let sum = thp.tiershorsepart_pension + thp.tiershorsepart_frais + thp.tiershorsepart_contract + thp.tiershorsepart_propriete
               	if(sum > 0) {
               		tiers_horse.push(th)
               	}
			})

			tiers.tiers_horse = tiers_horse

			return tiers 
		},

		loadHorseAllCurrentPartsOnline: async function(tiers_id) {
			const params = { 'tiers_id': tiers_id }
			const url = this.constructRoute(Constants.TIERS_HORSE_CURRENT_PART_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("TiersMixin::loadHorseAllCurrentPartsOnline", url)
			.catch(error => {
				console.error("TiersMixin::loadHorseAllCurrentPartsOnline => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},
		
		loadHorseAllCurrentParts: async function(tiers_id) {
			let tiers = await this.$storage.db.t('tiers')
			.then(table => {
				return table.get(parseInt(tiers_id))
			}).then(tier => {
				return TierTransformer.process(tier, 'withTiersHorseCurrentPart')
			})

			let tiers_horse = []

			await Promise.all(tiers.tiers_horse.map (async (th) => {
				const all_parts = await this.loadTiersCurrentPart(th.horse.horse_id)
				let thp = th.tiers_horse_part
                let sum = thp.tiershorsepart_pension + thp.tiershorsepart_frais + thp.tiershorsepart_contract + thp.tiershorsepart_propriete
               	if(sum > 0) {
					th.all_parts = all_parts
					th.parts_error = false
					// On regarde si la somme des parts du cheval est supérieure à 100 pour générer une donnée représentant cette erreur
					let parts_sum = {
						contract: 0,
						frais: 0,
						propriete: 0,
						pension: 0,
					}
					all_parts.tiers_horse.forEach((tiers) => {
						parts_sum.contract += tiers.tiers_horse_part.tiershorsepart_contract;
						parts_sum.frais += tiers.tiers_horse_part.tiershorsepart_frais;
						parts_sum.propriete += tiers.tiers_horse_part.tiershorsepart_propriete;
						parts_sum.pension += tiers.tiers_horse_part.tiershorsepart_pension;
					})
					if (parts_sum.contract > 100 || parts_sum.frais > 100 || parts_sum.propriete > 100 || parts_sum.pension > 100 ){
						th.parts_error = true
					}
               		tiers_horse.push(th)
               	}
			}))

			// tiers.tiers_horse.forEach(th => {
			// 	let thp = th.tiers_horse_part
            //     let sum = thp.tiershorsepart_pension + thp.tiershorsepart_frais + thp.tiershorsepart_contract + thp.tiershorsepart_propriete
            //    	if(sum > 0) {
            //    		tiers_horse.push(th)
            //    	}
			// })

			tiers.tiers_horse = tiers_horse

			return tiers 
		},

		loadHorsePart: async function(tiers_id) {
			return this.$storage.db.t('tiers')
			.then(table => {
				return table.get(parseInt(tiers_id))
			})
			.then(tier => {
				return TierTransformer.process(tier, 'withTiersHorse')
			})
		},

		async formattingTiersData (params) {
			params.phones.forEach(phone => {
				if(phone.phone_numero !== "") {
					const indicatifs_domtom = ['+262', '+590', '+594', '+596']
					const code_france = ['FRA', 'FR']
					const parseMaxRes = parseMax(phone.phone_indicatif+phone.phone_numero, phone.phone_country)
					if(!parseMaxRes || (parseMaxRes && !parseMaxRes.isValid())
						&& (!indicatifs_domtom.includes(phone.phone_indicatif) || !code_france.includes(phone.phone_country)
						|| !parseMax('+33' + phone.phone_numero, phone.phone_country).isValid())) {
						throw "toast.phone_not_valid"
					}
					else {
						const phone_formatted = parsePhoneNumber(phone.phone_indicatif+phone.phone_numero, phone.phone_country)
						phone.phone_numero = phone_formatted.nationalNumber
					}
				}
			})
			
			if(params.tiers_moral === true) {
				params.tiers_rs = params.tiers_rs.toUpperCase()
			}
			else {
				const rs_used = await this.$storage.db.t('raison_sociale_used')
				.then(table => {
					return table.toCollection().first()
				})

				let format = null
				if(rs_used) {
					format = await this.$storage.db.t('raison_sociale_configuration')
					.then(table => {
						return table.where({
							rsconfig_id: rs_used.rsused_format
						}).first()
					})
				}
				else {
					format = await this.$storage.db.t('raison_sociale_configuration')
					.then(table => {
						return table.where({
							rsconfig_format: 'rsconfig.format_default'
						}).first()
					})
				}

				params.tiers_lastname = params.tiers_lastname.toUpperCase()
				// params.tiers_rs = params.tiers_lastname + " " + params.tiers_firstname

				params.tiers_firstname = this.formatFirstName(params.tiers_firstname, ['-', ' '])

				switch (format.rsconfig_format)
				{
					case 'rsconfig.format_default':
						params.tiers_rs = params.tiers_lastname + ' ' + params.tiers_firstname;
						break;
					case 'rsconfig.format_capn':
						params.tiers_rs = this.getTrad('civility.' + params.tiers_civility) + ' ' + params.tiers_firstname + ' ' + params.tiers_lastname;
						break;
					case 'rsconfig.format_canp':
						params.tiers_rs = this.getTrad('civility.' + params.tiers_civility) + ' ' + params.tiers_lastname + ' ' + params.tiers_firstname;
						break;
					case 'rsconfig.format_cpn':
						params.tiers_rs = this.getTrad('civility_complete.' + params.tiers_civility) + ' ' + params.tiers_firstname + ' ' + params.tiers_lastname;
						break;
					case 'rsconfig.format_cnp':
						params.tiers_rs = this.getTrad('civility_complete.' + params.tiers_civility) + ' ' + params.tiers_lastname + ' ' + params.tiers_firstname;
						break;
					default:
						params.tiers_rs = params.tiers_lastname + ' ' + params.tiers_firstname;
						break;
				}
			}

			if(params.tiers_country != 75) {
				params.tiers_statut_rcs = null
				params.tiers_statut_rne = null
			}

			return params
		},

		formatFirstName(firstname, separators) {
			let firstname_split = firstname.toLowerCase()
			separators.forEach(separator => {
				firstname_split = firstname_split.split(separator)
				for (var i = 0; i < firstname_split.length; i++) {
					firstname_split[i] = firstname_split[i].charAt(0).toUpperCase() + firstname_split[i].substring(1);    
				}
				firstname_split = firstname_split.join(separator)
			})
	
			return firstname_split
		},

		tierExists(rs, ignoreTierId=0) {
			rs = rs.toLowerCase()
			ignoreTierId = parseInt(ignoreTierId)

			return this.$storage.db.t('tiers')
			.then(table => {
				return table.filter((tier) => {
					return tier.tiers_id !== ignoreTierId && tier.tiers_rs.toLowerCase() === rs
				})
			})
			.then(col => {
				return col.count()
			})
		},

		// Ajoute un tiers
		addTiers: async function(params) {
			if(params.tiers_country && typeof(params.tiers_town) == "string") {
				if(params.tiers_entity == 1) {
					const has_right = await this.checkHasReachEntityLimit()
					if(has_right) throw "tiers.max_entity_achieved"
				}
				const tiers_id = Common.getNegativeId()

				params = await this.formattingTiersData(params)

				await this.$storage.db.transaction(
					'rw',
					['tiers', 'phone'],
					() => {
						if(params.tiers_moral === true) {
							this.$storage.db.t('tiers')
							.then(table => {
								return table.add({
									tiers_id: tiers_id,
									tiers_valide: 1,
									tiers_address1: params.tiers_address1,
									tiers_address2: params.tiers_address2,
									tiers_country: params.tiers_country,
									tiers_currency: params.tiers_currency,
									tiers_entity: params.tiers_entity,
									tiers_mail: params.tiers_mail,
									tiers_moral: params.tiers_moral,
									tiers_postalcode: params.tiers_postalcode,
									tiers_rs: params.tiers_rs,
									tiers_town: params.tiers_town,
									tiers_website: params.tiers_website,
									tiers_alias: params.tiers_alias,
									tiers_siret: params.tiers_siret,
									tiers_siren: params.tiers_siren,
									tiers_vat_number: params.tiers_vat_number,
									tiers_statut_rcs: params.tiers_statut_rcs,
									tiers_statut_rne: params.tiers_statut_rne,
									tiers_lang: params.tiers_lang,
									tiers_region: params.tiers_region
								})
							})
						}
						else {
							this.$storage.db.t('tiers')
							.then(table => {
								return table.add({
									tiers_id: tiers_id,
									tiers_valide: 1,
									tiers_address1: params.tiers_address1,
									tiers_address2: params.tiers_address2,
									tiers_country: params.tiers_country,
									tiers_currency: params.tiers_currency,
									tiers_entity: params.tiers_entity,
									tiers_firstname: params.tiers_firstname,
									tiers_lastname: params.tiers_lastname,
									tiers_civility: params.tiers_civility,
									tiers_mail: params.tiers_mail,
									tiers_moral: params.tiers_moral,
									tiers_postalcode: params.tiers_postalcode,
									tiers_rs: params.tiers_rs,
									tiers_town: params.tiers_town,
									tiers_website: params.tiers_website,
									tiers_alias: params.tiers_alias,
									tiers_lang: params.tiers_lang,
									tiers_region: params.tiers_region
								})
							})
						}

						params.phones.forEach(phone => {
							if(phone.phone_numero != "") {
								const phone_id = Common.getNegativeId()
								let phone_type = phone.phone_type
								if(phone_type == this.getTrad('tiers.phone_type.fixe')) {
									phone_type = 'fixe'
								}
								else if(phone_type == this.getTrad('tiers.phone_type.portable')) {
									phone_type = 'portable'
								}
								this.$storage.db.t('phone')
								.then(table => {
									return table.add({
										phone_id: phone_id,
										phone_tiers: tiers_id,
										phone_indicatif: phone.phone_indicatif,
										phone_country: phone.phone_country,
										phone_numero: phone.phone_numero,
										phone_type: phone_type,
										phone_valide: 1,
										phone_libelle: phone.phone_libelle
									})
								})
							}
						})

						return tiers_id
					}
				)

				return tiers_id
			}
			else {
				throw "formulaire.erreur_champs_non_remplis"
			}
		},

		// Modifie un tiers
		editTiers: async function(params) {
			const tiers_id = parseInt(params.tiers_id)
			const has_right = await this.checkHasReachEntityLimit(tiers_id)

			params = await this.formattingTiersData(params)

			if(params.tiers_entity == 1 && has_right === true) {
				throw "tiers.max_entity_achieved"
			}
			await this.$storage.db.transaction(
				'rw',
				['tiers', 'phone'],
				async () => {
					this.$storage.db.t('tiers')
					.then(table => {
						table.update(tiers_id, params)
					})

					const phoneTable = await this.$storage.db.t('phone')

					await phoneTable
					.where('phone_tiers').equals(tiers_id)
					.invalid()

					params.phones.forEach(phone => {
						if(phone.phone_numero != "") {
							const phone_id = Common.getNegativeId()
							let phone_type = phone.phone_type
							if(phone_type == this.getTrad('tiers.phone_type.fixe')) {
								phone_type = 'fixe'
							}
							else if(phone_type == this.getTrad('tiers.phone_type.portable')) {
								phone_type = 'portable'
							}
							return phoneTable.add({
								phone_id: phone_id,
								phone_tiers: tiers_id,
								phone_indicatif: phone.phone_indicatif,
								phone_country: phone.phone_country,
								phone_numero: phone.phone_numero,
								phone_type: phone_type,
								phone_valide: 1,
								phone_libelle: phone.phone_libelle
							})
						}
					})

					return tiers_id
				}
			)
			TierCleaner.inst().onMutation([tiers_id])
			return tiers_id
		},

		checkHasReachEntityLimit: async function(tiers_id=0) {
			return this.$storage.db.t('tiers')
			.then(table => {
				return table.where('tiers_entity').equals(1)
			})
			.then(col => {
				return col.toArray()
			})
			.then(res => {
				if(tiers_id > 0) {
					const index = res.findIndex(tier => tier.tiers_id == tiers_id)

					if(index > -1) {
						res.splice(index, 1)
					}
				}
				
				return res.length >= this.getUserEntityLimit()
			})
		},

		addTiersContact: async function(tiers_id, contact_id) {
			const tierscontact_id = Common.getNegativeId()
			return this.$storage.db.t('tiers_contact')
			.then(table => {
				return table.add({
					tierscontact_id: tierscontact_id,
					tierscontact_valide: 1,
					tierscontact_contact: parseInt(contact_id),
					tierscontact_tiers: parseInt(tiers_id),
					tierscontact_favoris: 0
				})
			})
			.then(col => {
				return tierscontact_id
			})
		},

		loadRib: async function(tiers_id) {
			return this.$storage.db.t('tiers_rib')
			.then(table => {
				return table.where({
					tiersrib_tiers: parseInt(tiers_id)
				})
			})
			.then(col => {
				return col.transform(new TiersRibTransformer())
			})
		},

		loadRibByAccountingPlan: async function(accountingplan_id) {
			let accountingplan = await this.$storage.db.t('accounting_plan')
			.then(table => {
				return table.get(parseInt(accountingplan_id))
			})
			.then(col => {
				return AccountingPlanTransformer.process(col, 'light')
			})

			return this.loadRib(accountingplan.accountingplan_tiers)
		},

		loadClientAccount: async function(tiers_id) {
			return this.$storage.db.t('tiers_client_account')
			.then(table => {
				return table.where({
					tiersclientaccount_tiers: parseInt(tiers_id)
				})
			})
			.then(col => {
				return col.transform(new TiersClientAccountTransformer('withTiers'))
			})
			.then(col => {
				return col.filter(tca => tca.tiersclientaccount_accountingplan.accountingplan_archive != 1)
			})
		},

		checkTvaValidOnline: async function(vat_number, country) {
			// API TVA https://ec.europa.eu/taxation_customs/vies/rest-api/ms/FR/vat/FR86490377058
			if (!vat_number || !country) return 2 // 2 = non valide
			
			const params = { vat_number, country }
			const url = this.constructRoute(Constants.CHECK_VAT_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("TiersMixin::checkVatValidOnline", url)
			.catch(error => {
				console.error("TiersMixin::checkVatValidOnline => ERROR", error)
				return null
			})
			if(result.retour) return result.retour
		},

		saveJuridique: async function(params, deleted_ribs, vat_valid) {
			if(typeof(params.tiers_vat_number) == "string" || params.tiers_vat_number == null &&
			typeof(params.tiers_siret) == "string" || params.tiers_siret == null &&
			typeof(params.tiers_siren) == "string" || params.tiers_siren == null &&
			params.ribs) {
				// Requete api pour check validité du num TVA
				if(!params.tiers_vat_number || params.tiers_vat_number == "" || vat_valid === null){
					params.tiers_vatcheck = "0" // non contrôlé si VAT vide
				}
				else if (!vat_valid) {
					params.tiers_vatcheck = "2" // incorrect
				} else {
					params.tiers_vatcheck = await this.checkTvaValidOnline(params.tiers_vat_number, params.tiers_country)
				}

				params.tiers_id = this.$sync.replaceWithReplicated('tiers', params.tiers_id)

				return this.$storage.db.transaction(
					'rw',
					['tiers_rib', 'tiers'],
					async () => {
						if(params.ribs.length != 0) {
							params.ribs.forEach(rib => {
								if(rib.tiersrib_id > 0) {
									return this.$storage.db.t('tiers_rib')
									.then(table => {
										return table.update(parseInt(rib.tiersrib_id), {
											tiersrib_content: rib.tiersrib_content
										})
									})
								}
								else {
									const tiersrib_id = Common.getNegativeId()

									this.$storage.db.t('tiers_rib')
									.then(table => {
										return table.add({
											tiersrib_id: tiersrib_id,
											tiersrib_tiers: parseInt(params.tiers_id),
											tiersrib_content: rib.tiersrib_content,
											tiersrib_valide: 1
										})
									})
								}
							})
						}

						if(deleted_ribs.length > 0) {
							for (let index = 0; index < deleted_ribs.length; index++) {
								const element = deleted_ribs[index];

								if(element > 0) {
									this.$storage.db.t('tiers_rib')
									.then(table => {
										return table
										.where('tiersrib_id')
										.equals(parseInt(element))
										.invalid()
									})
		
								}
							}
						}
						//Je vais delete les ribs potentiellement supprimé
						let siren = params.tiers_siren

						// Si VAT français et valide et pas de SIREN renseigné on récupère le SIREN grâce au VAT
						if ((!siren || siren == "") && params.tiers_vatcheck == 1 && params.tiers_vat_number.substring(0, 2) == 'FR'){
							siren = params.tiers_vat_number.substring(4, 13)
						}
						const tiers_siret = params.tiers_siret
						const tiers_siren = siren
						const tiers_vat_number = params.tiers_vat_number
						const tiers_vatcheck = params.tiers_vatcheck

						if(tiers_vatcheck == 2) {
							this.infoToast('toast.check_vat')
						}

						return this.$storage.db.t('tiers')
						.then(table => {
							return table.update(parseInt(params.tiers_id), {
								tiers_siret,
								tiers_siren,
								tiers_vat_number,
								tiers_vatcheck
							})
						})
					}
				)
			}
		},

		async editVatNumber(tiers_id, tiers_vat_number, country) {
			return this.$storage.db.t('tiers')
				.then(table => {
					// Si français on update le SIREN grâce au VatNumber
					if (country == 75) {
						table.update(parseInt(tiers_id), {
							tiers_siren: tiers_vat_number.substring(4, 13)
						})
					} 
					return table.update(parseInt(tiers_id), {
						tiers_vat_number: tiers_vat_number,
						tiers_vatcheck: 1
					})
				})
		},

		loadAllInvoicesByTiers: async function(tiers_id) {
			const url = this.constructRoute(Constants.TIERS_INVOICES_URL, {tiers_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("TiersMixin::loadAllInvoicesByTiers", url)
			if(result) return result.retour
			return null
		},

		listTiersPayment: async function(tiers_id) {
			const params = { 'tiers_id': tiers_id }
			const url = this.constructRoute(Constants.TIERS_PAYMENTS_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("TiersMixin::listTiersPayment", url)
			.catch(error => {
				console.error("TiersMixin::listTiersPayment => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		getCurrentAccount: async function(tiers_id) {
			let current_account = []
			let accounting_plan = await this.$storage.db.t('accounting_plan')
			.then(table => {
				return table.toCollection().primaryKeys()
			})

			for (let i = 0; i < accounting_plan.length; i++) {
				const cu = await this.$storage.db.t('current_account')
				.then(table => {
					return table.where({
						currentaccount_tiers: parseInt(tiers_id),
						currentaccount_accountingplan: parseInt(accounting_plan[i]),
					})
					.reverse()
					.first()
				})
				.then(current_account => {
					return CurrentAccountTransformer.process(current_account)
				})

				if(cu != undefined)
				{
					current_account.push(cu)
				}
			}

			return current_account
		},

		async replaceOrSynchronizeId(tiers_id) {
			// On récupère le nouvel ID
			if (tiers_id < 0) {
				tiers_id = this.$sync.replaceWithReplicated('tiers', tiers_id)
			}

			// Si le tiers n'est toujours pas synchro
			if (tiers_id < 0) {
				await this.$sync.force()
				await this.$sync.runOptionalWhishlist(['tiers'])
				tiers_id = this.$sync.replaceWithReplicated('tiers', tiers_id)
			}

			return tiers_id
		},

		getCurrentAccountDetails: async function(tiers_id) {
			const params = { 'tiers_id': tiers_id }
			const url = this.constructRoute(Constants.TIERS_CURRENT_ACCOUNT_DETAILS_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("TiersMixin::getCurrentAccountDetails", url)
			.catch(error => {
				console.error("TiersMixin::getCurrentAccountDetails => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		getNextDateDuedate: async function(tiers_id, date) {
			date = date.toDateInputValue()
			const params = { 'tiers_id': tiers_id, 'date': date }
			const url = this.constructRoute(Constants.TIERS_DATE_DUEDATE_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("TiersMixin::getDateDuedate", url)
			.catch(error => {
				console.error("TiersMixin::getDateDuedate => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},


		getConfigDuedate: async function(tiers) {
			const params = {tiers: tiers.join(",")}
			const url = this.constructRoute(Constants.TIERS_DUEDATE_CONFIG_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("TiersMixin::tiers", url)
			if(result) return result.retour
			return null
		},
		loadTiersGroup: async function(tiers_id) {
			const params = {tiers_id: tiers_id }
			const url = this.constructRoute(Constants.TIERS_GROUPE_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("TiersMixin::loadTiersGroup", url)
			if(result) return result.retour
			return null
		},
		searchFromHubspot: async function(search_val) {
			const params = {search_val: search_val }
			const url = this.constructRoute(Constants.TIERS_HUBSPOT_SEARCH_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("TiersMixin::searchFromHubspot", url)
			if(result) return result.retour
			return null
		},

		saveHubspotTiers: async function(tiers_id, hubspot_id) {
			const params = {
				tiers_id: tiers_id,
				hubspot_id: hubspot_id
			}
			const url = this.constructRoute(Constants.TIERS_HUBSPOT_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("TiersMixin::saveHubspotTiers", url, params, false)
			.catch(error => {
				console.error("TiersMixin::saveHubspotTiers => ERROR", error)
				return null
			})
			this.successToast()
		},

		loadDuedateConfig: async function() {
			const url = this.constructRoute(Constants.DUEDATE_CONFIG_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("TiersMixin::loadDuedateConfig", url)
			if(result) return result.retour
			return null
		},

		saveDuedateConfigTiers: async function(tiers_id, duedateconfig_id) {

			await this.$storage.db.t('tiers')
			.then(table => {
				return table.update(parseInt(tiers_id), {
					tiers_duedateconfig: duedateconfig_id
				})
			})


			await this.$sync.runOptionalWhishlist(['tiers'])
			return true
		},

		savePaymentMethodTiers: async function(tiers_id, paymentmethod_id) {

			await this.$storage.db.t('tiers')
			.then(table => {
				return table.update(parseInt(tiers_id), {
					tiers_paymentmethod: paymentmethod_id
				})
			})

			await this.$sync.runOptionalWhishlist(['tiers'])
			return true
		},

		saveGocardlessTiers: async function(tiers_id, gocardless_id) {

			const params = {
				tiers_id: tiers_id,
				gocardless_id: gocardless_id,
			}
			const url = this.constructRoute(Constants.TIERS_GOCARDLES_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("TiersMixin::saveGocardlessTiers", url, params, false)
			.catch(error => {
				console.error("TiersMixin::saveGocardlessTiers => ERROR", error)
				return null
			})
			
			if(result) {
				return result.retour
			}
			else {
				return result
			}
		},

		calculNextDateDuedate: function(config, date){
			let next_date = new Date(date)

			if(config.endmonth == 2) {
				let y = next_date.getFullYear()
				let m = next_date.getMonth()
				next_date = new Date(y, m + 2, 0);
			}
			else{
				next_date = next_date.addDays(config.days)
				
				if(config.endmonth)
				{
					let y = next_date.getFullYear()
					let m = next_date.getMonth()
					next_date = new Date(y, m + 1, 0);
				}
			}

			return next_date

			/*
			$next_date = date('Y-m-d', strtotime($from_date . ' +'.$duedate_config->duedateconfig_days.' days'));
			if($duedate_config->duedateconfig_endmonth == 1)
			{
				$date_tab = explode('-', $next_date);
				$next_date = gmdate("Y-m-d",mktime(0, 0, 0, $date_tab[1]+1, 0, $date_tab[0]));
			}
			*/
		},

		async saveClientAccountNumber(tiersclientaccount_id, tiersclientaccount_number, tiersclientaccount_vat_code) {
			await this.$storage.db.t('tiers_client_account')
			.then(table => {
				return table.update(parseInt(tiersclientaccount_id), {
					tiersclientaccount_number: tiersclientaccount_number,
					tiersclientaccount_vat_code: tiersclientaccount_vat_code
				})
			})
		},

		getTiers: function(search_param) {
			return this.$storage.db.t('tiers')
				.then(table => {
					return table.where('tiers_rs')
					.equalsIgnoreCase(search_param)
				}).then(col => {
					return col.transform(new TierTransformer('light'))
				})
		},

		addTiersSecondaire: function(horse_id, tiers_id, fonction_label, tierscontact_id = null) {
			const tiershorse_id = Common.getNegativeId()

			return this.$storage.db.transaction(
				'rw',
				['tiers_horse'],
				() => {
					this.$storage.db.t('tiers_horse')
					.then(table => {
						return table.add({
							tiershorse_id: tiershorse_id,
							tiershorse_horse: horse_id,
							tiershorse_tiers: tiers_id,
							tiershorse_valide: 1,
							tiershorse_fonction: fonction_label,
							tiershorse_tierscontact: tierscontact_id
						})
					})
				}
			)
		},

		editTiersSecondaire: function(tiershorse_id, horse_id, tiers_id, fonction_label, tierscontact_id = null) {
			return this.$storage.db.transaction(
				'rw',
				['tiers_horse'],
				() => {
					this.$storage.db.t('tiers_horse')
					.then(table => {
						return table.update(parseInt(tiershorse_id),
						{
							tiershorse_horse: horse_id,
							tiershorse_tiers: tiers_id,
							tiershorse_valide: 1,
							tiershorse_fonction: fonction_label,
							tiershorse_tierscontact: tierscontact_id
						})
					})
				}
			)
		},

		invalidTiersSecondaires: async function(tiershorse_ids) {
			await this.$storage.db.transaction(
				'rw',                
				['tiers_horse'],
				() => {
					this.$storage.db.t('tiers_horse')
					.then(table => {
						return table.filter(tiershorse => tiershorse_ids.includes(tiershorse.tiershorse_id))
						.invalid()
					})
				}
			)
		},

		loadTiersRelations: async function(tiers_id) {
			return this.$storage.db.t('tiers_relations')
			.then(table => {
				return table.where({
					tiersrelations_from: parseInt(tiers_id)
				})
			})
			.then(col => {
				return col.transform(new TiersRelationsTransformer())
			}) 
		},

		addTiersRelations: function(tiers_from_id, tiers_to_id, fonction_label, contact_id = null) {
			const tiersrelations_id = Common.getNegativeId()

			return this.$storage.db.transaction(
				'rw',
				['tiers_relations'],
				() => {
					this.$storage.db.t('tiers_relations')
					.then(table => {
						return table.add({
							tiersrelations_id: tiersrelations_id,
							tiersrelations_from: tiers_from_id,
							tiersrelations_to: tiers_to_id,
							tiersrelations_valide: 1,
							tiersrelations_fonction: fonction_label,
							tiersrelations_contact: contact_id
						})
					})
				}
			)
		},

		editTiersRelations: function(tiersrelations_id, tiers_from_id, tiers_to_id, fonction_label, contact_id = null) {
			return this.$storage.db.transaction(
				'rw',
				['tiers_relations'],
				() => {
					this.$storage.db.t('tiers_relations')
					.then(table => {
						return table.update(parseInt(tiersrelations_id),
						{
							tiersrelations_from: tiers_from_id,
							tiersrelations_to: tiers_to_id,
							tiersrelations_valide: 1,
							tiersrelations_fonction: fonction_label,
							tiersrelations_contact: contact_id
						})
					})
				}
			)
		},

		invalidTiersRelations: async function(tiersrelations_ids) {
			await this.$storage.db.transaction(
				'rw',                
				['tiers_relations'],
				() => {
					this.$storage.db.t('tiers_relations')
					.then(table => {
						return table.filter(tiersrelations => tiersrelations_ids.includes(tiersrelations.tiersrelations_id))
						.invalid()
					})
				}
			)
		},

		loadRelatedTiers: async function(tiers_id) {
			return this.$storage.db.t('tiers_relations')
			.then(table => {
				return table.where({
					tiersrelations_to: parseInt(tiers_id)
				})
			})
			.then(col => {
				return col.transform(new TiersRelationsTransformer())
			}) 
		},

		printInfosTiers: async function(tiers_ids, model_id, format_id, start_date, end_date) {
			const params = {
				tiers_ids: tiers_ids,
				model_id: model_id,
				format_id: format_id,
				start_date,
				end_date
			}
			const url = Constants.TIERS_INFOS_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("TiersMixin::printInfosTiers", url, params, false)
			.catch(error => {
				console.error("TiersMixin::printInfosTiers => ERROR", error)
				return null
			})
			
			if(result) {
				Common.base64ToPdf(result, "informations_tiers.pdf")
			}
			else {
				return result
			}
		},

		duplicateTiers: async function(tiers_id) {
			const params = {
				tiers_id: tiers_id
			}
			const url = this.constructRoute(Constants.TIERS_DUPLICATE_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("TiersMixin::duplicateTiers", url, params, false)
			.catch(error => {
				console.error("TiersMixin::duplicateTiers => ERROR", error)
				return null
			})
			
			if(result) {
				return result.retour
			}
			else {
				return result
			}
		},
		getHubspotId: async function(tiers_id) {
			const params = {
				tiers_id: tiers_id,
			}
			const url = this.constructRoute(Constants.TIERS_HUBSPOT_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("TiersMixin::getHubspotId", url)
			.catch(error => {
				console.error("TiersMixin::getHubspotId => ERROR", error)
				return null
			})
			
			if(result) {
				return result.retour
			}
			else {
				return result
			}
		},
		saveTiersGroup: async function(principal_tiers_id, second_tiers_id) {
			const params = {
				principal_tiers_id: principal_tiers_id,
				second_tiers_id: second_tiers_id,
			}
			const url = this.constructRoute(Constants.TIERS_SAVE_GROUPE_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("TiersMixin::saveTiersGroup", url, params, false)
			.catch(error => {
				console.error("TiersMixin::saveTiersGroup => ERROR", error)
				return null
			})
			
			if(result) {
				return result.retour
			}
			else {
				return result
			}
		},

		saveDeleteTiersGroup: async function(tiers_ids){
			
        	const url = Constants.TIERS_GROUPE_DELETE_URL + "?licence_key="+Constants.USER_LICENCE_KEY

			const response = await this.$request.request_delete_api("TiersMixin::saveDeleteTiersGroup", url, {tiers_ids})
			.catch(e => {
				console.log(e);
				console.error("TiersMixin::saveDeleteTiersGroup", e)
				this.failureToast("toast.info_save_failed")
				return null
			})

			return response
		},

		// Supprimer un Tier si l'adresse mail a changer
		deleteUsersTiers: async function(tiers_id) {

			const url = Constants.TIERS_USERSTIERS_DELETE_URL + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_delete_api("TiersMixin::deleteUsersTiers", url, {tiers_id})
			.catch(error => {
				console.error("TiersMixin::deleteUsersTiers => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		fusionTiers: async function(from_tiers_id, to_tiers_id) {
			const params = {
				from_tiers_id: from_tiers_id,
				to_tiers_id: to_tiers_id,
			}
			const url = this.constructRoute(Constants.TIERS_FUSION_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("TiersMixin::fusionTiers", url, params, false)
			.catch(error => {
				console.error("TiersMixin::fusionTiers => ERROR", error)
				return null
			})
			if(result) {
				return result.retour
			}
			else {
				return result
			}
		},

		importTier: async function(params) {
            const url = Constants.TIERS_IMPORT + "?licence_key="+Constants.USER_LICENCE_KEY

            const result = await this.$request.request_post_api("TiersMixin::importTier", url, {tiers: params}, false)
            .catch(error => {
                console.error("TiersMixin::importTier => ERROR", error)
                if(error.response.data.message != "")
                {
                    this.failureToast(error.response.data.message)
                }
                else
                {
                    this.failureToast("toast.info_save_failed")
                }
                return null
            })
			if(result) {
				return result.retour
			}
			else {
				return result
			}
        },		

		transfertHorse: function(tiershorse_ids, tiers_id) {
			return this.$storage.db.transaction(
                'rw',
                ['tiers_horse'],
                () => {
					this.$storage.db.t('tiers_horse')
					.then(table => {
						return table.where('tiershorse_id')
							.anyOf(tiershorse_ids)
							.modify({tiershorse_tiers: tiers_id})
					})
                }
            )
		},
		archiveTiers: function(tiers_ids) {
			return this.$storage.db.transaction(
                'rw',
                ['tiers'],
                () => {
					this.$storage.db.t('tiers')
					.then(table => {
						return table.where('tiers_id')
							.anyOf(tiers_ids)
							.modify({tiers_archive: 1})
					})
                }
            )
		},

		unarchiveTiers: function(tiers_ids) {
			return this.$storage.db.transaction(
                'rw',
                ['tiers'],
                () => {
					this.$storage.db.t('tiers')
					.then(table => {
						return table.where('tiers_id')
							.anyOf(tiers_ids)
							.modify({tiers_archive: 0})
					})
                }
            )
		},

		getCityAndCp: async function(search_val, town) {
			const params = {
				search_val: search_val,
				town: town
			}

			const url = this.constructRoute(Constants.CITIES_AND_CP_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("TiersMixin::getCityAndCp", url, params, false)
			if(result) return result
			return null
		},

		saveTiersDescription(tiers_id, description) {
			return this.$storage.db.transaction(
                'rw',
                ['tiers'],
                () => {
					this.$storage.db.t('tiers')
					.then(table => {
						return table.update(parseInt(tiers_id), {
							tiers_description: description
						})
					})
                }
            )
		},

		async export_tiers() {
            const headers = 
			{
				tiers_id: "Id",
				tiers_rs: "Raison sociale",
				tiers_lastname: "Nom",
				tiers_firstname: "Prénom",
				tiers_address1: "Adresse",
				tiers_address2: "Complément d'adresse",
				tiers_postalcode: "Code postal",
				tiers_town: "Ville",
				pays: "Pays",
				tiers_mail: "Email",
				phones_number: "Tél",
				tiers_website: "Site internet"
			}

			const items = await this.$storage.db.t('tiers')
				.then(table => {
					return table.where({
						tiers_archive: 0
					})
				})
				.then(col => {
					return col.transform(new TierTransformer('withPhone'))
				})

            this.downloadJsonToCsv(
                items,
                headers,
                'all_tiers.csv',
                ';',
                []
            )
		},

		async switchFormatRs(format, lang) {
			const params = {
				format: format,
				lang: lang
			}

			const url = this.constructRoute(Constants.TIERS_SWITCH_FORMATS_RS, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("TiersMixin::switchFormatRs", url, params, false)
			.catch(error => {
				console.error("TiersMixin::switchFormatRs => ERROR", error)
				return null
			})

			if(result) return result.retour
			return null
		},

		async getCurrentFormatRs() {
			return this.$storage.db.t('raison_sociale_used')
			.then(table => {
				return table.toCollection().first()
			})
		},

		getMostUsedCmep: async function(tiers_id) {
            const url = this.constructRoute(Constants.TIERS_MOST_USED_CMEP, {tiers_id: tiers_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("ContractMixin::getContractConfigDefaultCmep", url)
            .catch(error => {
                console.error("ContractMixin::getContractConfigDefaultCmep => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },

		getGocardlessUrl: async function(tiers_id) {
            const url = this.constructRoute(Constants.TIERS_GOCARDLES_URL, {tiers_id: tiers_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("TiersMixin::getGocardlessUrl", url)
            .catch(error => {
                console.error("TiersMixin::getGocardlessUrl => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },

        searchAPIPappers: async function(val, type, salon = false, jeton = true) {
            let url = this.constructRoute(Constants.TIERS_PAPPERS_URL, {val: val, type: type}) + "?licence_key="+Constants.USER_LICENCE_KEY
            if(salon) {
            	url += '&salon=true';
            }
			url += '&jeton=' + jeton
            const result = await this.$request.request_get_api("TiersMixin::searchAPIPappers", url)
            .catch(error => {
                console.error("ContractMixin::searchAPIPappers => ERROR", error)
                return null
            })
			if(result.retour && result.retour.error) {
				this.failureToast(result.retour.message)
				return null
			}
            if(result.retour) return result.retour
            return null
        },

		getVATFromPappers: async function(val){
			let url = this.constructRoute(Constants.TIERS_PAPPERS_VAT_URL, {val: val}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("TiersMixin::getVATFromPappers", url)
			.catch(error => {
                console.error("ContractMixin::searchAPIPappers => ERROR", error)
                return null
            })
			if(result.retour && result.retour.error) {
				return null
			}
            if(result.retour) return result.retour
            return null
		},

		getRNEFromPappers: async function(val){
			let url = this.constructRoute(Constants.TIERS_PAPPERS_RNE_URL, {val: val}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("TiersMixin::getRNEFromPappers", url)
			.catch(error => {
                console.error("ContractMixin::searchAPIPappers => ERROR", error)
                return null
            })
			if(result.retour && result.retour.error) {
				return null
			}
            if(result.retour) {
				return String(result.retour).charAt(0).toUpperCase() + String(result.retour).slice(1);
			} 
            return null
		},
		
		save_tiers_coordinates: async function(tiers_id, lat, lng) {
			return this.$storage.db.t('tiers')
			.then(table => {
				return table.update(parseInt(tiers_id), {
					tiers_latitude: lat,
					tiers_longitude: lng
				})
			})
		},

		sendTiersMail: async function(model_ids, expeditor) {
			const params = {
				model_ids: model_ids,
				expeditor: expeditor
			}

			const url = Constants.SEND_TIERS_MAIL + "?licence_key=" + Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("TiersMixin::sendTiersMail", url, params, false)
				.catch(error => {
					console.log(error)
					console.error("TiersMixin::sendTiersMail => ERROR", error)
					return 'error'
				})

			return result
		},

		async deleteMediaTiers(filename, tiers_id) {

			await this.$storage.db.t('tiers_media')
			.then(table => {
				return table.where({
					tiersmedia_filename: filename,
					tiersmedia_tiers: parseInt(tiers_id)
				}).modify({tiersmedia_valide: 0 })
			})
		},

        async uploadMediaTiers (file, tiers_id) {
			const ALLOWED_TYPES = [
				"image/png",
				"image/jpeg",
				"image/jpg",
				'image/webp'
			]

			if (ALLOWED_TYPES.indexOf(file.type) === -1) {
				throw new Error('Invalid file type')
			}

			let filename
			const mediaTable = await this.$storage.db.t('tiers_media')

			await this.$storage.db.transaction(
				'rw',
				['_files', 'tiers_media'],
				async () => {
					filename = this.$sync.uploadFile(file)

					await mediaTable
					.where('tiersmedia_tiers')
					.equals(tiers_id)
					.invalid()

					mediaTable.add({
						tiersmedia_id: Common.getNegativeId(),
						tiersmedia_tiers: parseInt(tiers_id),
						tiersmedia_type: 'image',
						tiersmedia_filename: filename,
						tiersmedia_valide: 1
					})
				}
			)
			return filename
        },

		loadFirstTiers: async function() {
			return this.$storage.db.t('tiers')
			.then(table => {
				return table.where({
					tiers_archive: 0
				}).first()
			})
			.then(item => {
				return TierTransformer.process(item, 'light')
			})
		},

		sendCAInvitation: async function(tiers_ids) {
			const url = Constants.SEND_INVITATION_CA + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("TiersMixin::sendCAInvitation", url, {tiers_ids}, false)
			.catch(error => {
				console.error("TiersMixin::sendCAInvitation => ERROR", error)
				return null
			})
		},

		sendTiersComptaMail: async function(tiers_id) {
			const url = this.constructRoute(Constants.SEND_TIERS_COMPTA_MAIL, {tiers_id: tiers_id}) + "?licence_key=" + Constants.USER_LICENCE_KEY
            await this.$request.request_post_api("TiersMixin::sendTiersComptaMail", url)
            .catch(error => {
                console.error("TiersMixin::sendTiersComptaMail => ERROR", error)
                return null
            })
		},

		addTiersWithRIB: async function(params) {
			let tiers_vatcheck = 0
			if(params.tiers_vat_number !== '' && this.checkVAT(params.tiers_vat_number, params.tiers_country)) {
				tiers_vatcheck = await this.checkTvaValidOnline(params.tiers_vat_number, params.tiers_country)
				if(tiers_vatcheck == 2) {
					this.infoToast('toast.check_vat')
				}
			}

			const tiers_id = Common.getNegativeId()
			await this.$storage.db.t('tiers')
			.then(table => {
				return table.add({
					tiers_id: tiers_id,
					tiers_valide: 1,
					tiers_address1: params.tiers_address1 ?? '',
					tiers_address2: params.tiers_address2 ?? '',
					tiers_country: params.tiers_country,
					tiers_entity: 0,
					tiers_mail: params.tiers_mail,
					tiers_moral: params.tiers_moral,
					tiers_postalcode: params.tiers_postalcode,
					tiers_rs: params.tiers_rs,
					tiers_town: params.tiers_town,
					tiers_alias: params.tiers_alias,
					tiers_siret: params.tiers_siret,
					tiers_siren: params.tiers_siren,
					tiers_vat_number: params.tiers_vat_number,
					tiers_lang: params.tiers_lang,
					tiers_region: params.tiers_region,
					tiers_vatcheck: tiers_vatcheck,
					tiers_statut_rne: params.tiers_statut_rne,
					tiers_archive: 0
				})
			})

			await this.$storage.db.t('tiers_rib')
			.then(table => {
				return table.add({
					tiersrib_id: Common.getNegativeId(),
					tiersrib_tiers: tiers_id,
					tiersrib_content: params.tiers_rib,
					tiersrib_valide: 1
				})
			})

			return tiers_id
		},

		editTiersLight: async function(tiers_id, params) {
			await this.$storage.db.t('tiers')
				.then(table => {
					table.update(tiers_id, params)
				})

			TierCleaner.inst().onMutation([tiers_id])
			return tiers_id
		},

		getCountryVatRegex: function() {
			return {
				"57" : /^\d{9}$/, 			// "Allemagne",
				"13" : /^U\d{8}$/, 			// "Autriche",
				"21" : /^(0|1)\d{9}$/, 		// "Belgique",
				"290": /^(0|1)\d{9}$/, 		// "Belgique 2 (België)",
				"23" : /^\d{9,10}$/, 		// "Bulgarie",
				"55" : /^\w{9}$/, 			// "Chypre",
				"100": /^\d{11}$/, 			// "Croatie",
				"59" : /^\d{8}$/, 			// "Danemark",
				"68" : /^.\d{7}.$/, 		// "Espagne",
				"64" : /^\d{9}$/, 			// "Estonie",
				"70" : /^\d{8}$/, 			// "Finlande",
				"75" : /^\w{2}\d{9}$/, 		// "France",
				"77" : /^\d{9}$/, 			// "Royaume-Uni",
				"91" : /^\d{9}$/, 			// "Grèce",
				"102": /^\d{8}$/, 			// "Hongrie",
				"104": /^\d{7}\D{1,2}$/, 	// "Irlande",
				"111": /^\d{11}$/, 			// "Italie",
				"135": /^\d{11}$/, 			// "Lettonie",
				"133": /^(\d{9}|\d{12})$/, 	// "Lituanie",
				"134": /^\d{8}$/, 			// "Luxembourg",
				"151": /^\d{8}$/, 			// "Malte",
				"164": /^(?=(?:\D*\d){11}\D*$)(?=[^B]*B[^B]*$)[A-Za-z0-9]{12}$/, // "Pays-Bas",
				"177": /^\d{10}$/, 			// "Pologne",
				"182": /^\d{9}$/, 			// "Portugal",
				"56" : /^\d{8,10}$/, 		// "République Tchèque",
				"187": /^\d{2,10}$/, 		// "Roumanie",
				"228": /^\d{10}$/, 			// "Slovaquie",
				"227": /^\d{8}$/, 			// "Slovénie",
				"194": /^\d{10}01$/, 		// "Suède",
			}
		},

		checkVAT(vatNumber, country){
			if (!vatNumber || !country) return true
			const countryRegexVat = this.getCountryVatRegex()
			const vatNumberCountryCode = vatNumber.slice(0,2)
			const vatNumberWithoutCountryCode = vatNumber.substring(2)

			if (Countries.fr.iso[country] != vatNumberCountryCode) return false

			const regex = new RegExp(countryRegexVat[country])
			return countryRegexVat[country] ? regex.test(vatNumberWithoutCountryCode) : null
		},

		checkSiren(siren){
			const regex = new RegExp(/^\d{9}$/)
			return regex.test(siren)
		},

		checkSiret(siret){
			const regex = new RegExp(/^\d{14}$/)
			return regex.test(siret)
		},
		
		async getGoogleImagePics(q){
			const url = this.constructRoute(Constants.TIERS_IMG_SEARCH_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY+"&query="+q
			const result = await this.$request.request_get_api("TiersMixin::imgSearch", url)
			.catch(error => {
				console.error("TiersMixin::getGoogleImagePics => ERROR", error)
				return null
			})
			if(result.retour && !result.retour.error &&result.retour.search_metadata.status === "Success") {
				let images = result.retour.images_results
				images = images.filter((image) => {
					return image.original
				})
				.map((image) => {
					return {
						thumbnail: image.thumbnail,
						file_url: image.original
					}
				})
				return images
			}
			return null
		},

		async getPicFileFromLink(link){
			const params = {
				link: link
			}
			const url = Constants.TIERS_GET_IMG_FROM_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("TiersMixin::getPicFileFromLink", url, params, false)
			.catch(error => {
				console.error("TiersMixin::getPicFileFromLink => ERROR", error)
				return null
			})
			return result.retour
		}
	}
}

export default TiersMixin
